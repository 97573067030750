import React, { useRef, useEffect, useState } from 'react'



export const usePhoneHeight = (height: number) : [any] => {

    const [chartHeight, setChartHeight] = useState(height);

    useEffect(() => {
      
        const handleResize = () => {
          const innerHeight = window.innerHeight;
          // console.log("WINDOW SIZE", innerHeight, window.innerWidth);
          if (innerHeight < height)
          {
            // console.log("CHART HEIGHT BEFORE", chartHeight);
            setChartHeight(innerHeight-100);
            // console.log("CHART HEIGHT AFTER", chartHeight);
          } else {
            setChartHeight(height);
          }
  
          return;
        }
  
        // https://www.pluralsight.com/guides/re-render-react-component-on-window-resize
        window.addEventListener('resize', handleResize);
  
        return function cleanup() {
          window.removeEventListener('resize', handleResize);
        }
  
        // height is a prop. Whenever a prop changes, it triggers a rerender. 
  
      }, []);

      return [chartHeight];
}