import React, { useEffect, useState, useRef } from 'react'
import { css } from '@emotion/css'
import tw, { GlobalStyles } from "twin.macro"



// MAPBOX specific
import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax



import { scrollflyto } from '../../constants/scrollflyto';

// RAB TODO ERR URGENT restore scrollama
import scrollamaNS from '../../scrollama'


mapboxgl.accessToken = 
'pk.eyJ1IjoicmFscGhicm9va3MiLCJhIjoiY2wxNm10dzlmMDg2dTNkcG5zYXppbGh5aCJ9.SynYRCmoeCDgpT0vXWgt2A';


const tailwindStyle = css`
@tailwind base;
@tailwind components;
@tailwind utilities;
`;




const ChartStyle = css`

.map-container {
    display: flex;
    flex-flow: column;
    height: 70vw;
    flex: 1 1 auto;
}

`;


const composedStyle = css`
    ${tailwindStyle};
    ${ChartStyle};
`;

type CombinedProps = ICityMapProps ;

// RAB TODO ERR URGENT - put back scrollamaNS.responseType for activeResponse and rm interface responseType

interface responseType {
    element: any
    index: number
    direction: string
}


interface ICityMapProps {
    className: string
    activeResponse?: responseType 
    mapHeightString: string
    selectedIds?: string[];
    handleCityMapLoading: React.Dispatch<React.SetStateAction<boolean>>;
    displayIds?: string[]
  }

// TODO does this solve the regex problem 
function numberWithCommas(x) {
    // this is the place where I am likely having the regex error
    //return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    return x.toString();
}

export const CityMap: React.FC<CombinedProps> = ({className, children, activeResponse,  mapHeightString, 
        selectedIds, handleCityMapLoading, displayIds}) => {

    // https://github.com/redwoodfusion/goto-map/blob/db9d7656f4acb6246b8b0210f7d245e3cd58eb28/src/components/sample/MapPorygon.tsx

    // This is the element where the map is going to be rendered
    // a reference persists for the full lifetime of the component
    const mapContainer = useRef<HTMLDivElement | null>(null);
    const [mapInstance, setMapInstance] = useState<mapboxgl.Map>();
    const [mapInstanceInit, setMapInstanceInit] = useState(false);

    const [displayIdPopup, setDisplayIdPopup] = useState<mapboxgl.Popup>();

    // TODO: Change Default to what you need for your blog
    const [lng, setLng] = useState(-98);
    const [lat, setLat] = useState(38.88);
    const [zoom, setZoom] = useState(1);
    const [minZoom, setMinZoom] = useState(1);

    // The app is loading until proven otherwise
    const [isLoading, setIsLoading] = useState(true);

    // When it is dependent on data - the map shows for a fraction of a second

    useEffect(() => {
        //if (map.current) return; //init map only once
        if (!mapContainer.current) return;


        const map = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/ralphbrooks/cl86cipdg000a14n3weeqd068',
            center: [lng, lat],
            zoom: zoom,
            minZoom: minZoom
            });


        map.on('load', () => {
            handleCityMapLoading(true);

            //const layerName = 'houses-sold-2022-09-17-tileset'
            //const layer = map.getLayer(layerName);
            //console.log("LAYER INFO", layer);


            // https://docs.mapbox.com/mapbox-gl-js/example/vector-source/


            // Type geojson would allow you to add in data manually
            map.addSource('house-geodata', {
                type: 'vector',
                url: 'mapbox://ralphbrooks.cl86cdqz30hwi20plsgeb0jjh-32jih'
            });

            // how do you know where you are painting in the stack?
            // Because source is of type vector, source-layer is required
            //                 'filter': ['in', 'zid', '']

            // 'fill-outline-color': '#FF0000',
            // 'fill-color': '#FF0000',
            // 'fill-opacity': 1.0

            map.addLayer(
                {
                'id': 'houses-highlighted',
                'type': 'circle',
                'source': 'house-geodata',
                'source-layer': 'houses-sold-2022-09-17-tileset',
                'layout': {
                    // Make the layer visible by default.
                    'visibility': 'none'
                    },
                'paint': {
                'circle-color': '#FF0000',

                },

                },
                'settlement-subdivision-label'
                ); 

            map.resize();

        });

        map.on('render', (e) => {
            if(e.target && e.target.loaded())
            {
                // console.log("THE MAP IS LOADED");
                handleCityMapLoading(false);
            }
            
        });

        map.on('click', (event) =>{
            // If the user clicked on one of your markers, get its information.
            const features = map.queryRenderedFeatures (event.point, {
                layers: ['houses-sold-2022-09-17-tileset']
            });

            if (!features.length){
                return;
            }

            const feature = features[0];

            const saledate = feature.properties.saledate ? feature.properties.saledate : ""
            // console.log("feature", saledate)
            const zid = feature.properties.zid ? feature.properties.zid : "";

            // create a popup and add it to the map
            // `<p>zid:${zid}</p>
            
            const popup = new mapboxgl.Popup({offset: [0, -15]})
                .setLngLat(feature.geometry.coordinates)
                .setHTML(
                    `<p>Date Sold:${saledate}</p>
                     <p>Pending Price: $${numberWithCommas(feature.properties.pending_price)}</p>
                     <p>Address: ${feature.properties.addressStreet},</p>
                     <p> ${feature.properties.addressCity}, ${feature.properties.addressState}</p>

                    `
                )
                .addTo(map);

        })



        // After the last frame rendered before the map enters an "idle" state.
        map.on('idle', () => {
            const layerName = 'houses-sold-2022-09-17-tileset'
            const layer = map.getLayer(layerName);
    
        });


        // get bearing center zoom and pitch
        map.on('move', () => {
            const lng = map.getCenter().lng.toFixed(4);
            const lat = map.getCenter().lat.toFixed(4);
            // TODO START HERE
            const bearing = map.getBearing();
            const zoom = map.getZoom();
            const pitch = map.getPitch();

            // console.log("lng : " + lng + " Lat: " + lat + " Bearing: " + bearing + " Zoom: " + zoom + " Pitch: "+ pitch);
        })

        // One approach is to import tileset into mapbox studio
        // https://stackoverflow.com/questions/49705905/adding-a-shapes-files-as-a-layer-on-mapbox-map


        // https://stackoverflow.com/questions/44394573/mapbox-gl-js-style-is-not-done-loading
        map.on('styledata', () => {
            const waiting = () => {
                if (!map.isStyleLoaded()){
                    // console.log("STYLE is NOT LOADED");
                    setTimeout(waiting, 200);
                } else {
                    if(isLoading){
                        // console.log("DOTS ARE SHOWING")
                        setIsLoading(false);
                    }
                    
                }
            }
            waiting();
        })

        // http://bl.ocks.org/ryanbaumann/04c442906638e27db9da243f29195592




        setMapInstance(map);
        setMapInstanceInit(true);

    }, []);

    // RAB TODO ERR URGENT - enable this useeffectblock and ref sublime
    useEffect(() => {

        // If map init has not yet occurred then do nothing
        if (!mapInstance) return;
        // if the map is still loading the style url, do nothing
        if (isLoading) return;
        
        const layerName = 'houses-sold-2022-09-17-tileset';

        // typescript needs to have each of the settings that occur
        // turn on green dots

        if (activeResponse !== undefined) {
            // console.log("show dots", scrollflyto)
            
            
            if (scrollflyto[activeResponse.index].showdots){
                

                // TURN ON CIRCLE OPACITY to 0.5
        
                // interpolation needs to occur between 0 and 0.5 over some duration
                mapInstance.setPaintProperty(layerName, 'circle-opacity', 0.5)
                // set stroke opacity to 0.5 - this is a PAINT property
                mapInstance.setPaintProperty(layerName, 'circle-stroke-opacity', 0.5)


                // TODO - how do I refine query based on ZIDs from outside the component
                const allFeatures = mapInstance.queryRenderedFeatures( {
                    layers: ['houses-sold-2022-09-17-tileset']
                });



                // // This is a manual matching 
                // // https://docs.mapbox.com/mapbox-gl-js/example/using-box-queryrenderedfeatures/

                if( selectedIds !== undefined){
                    // console.log("selected ids", selectedIds)
                    const zids: string[] = selectedIds;
                    // console.log("2nd parm",['in', 'zid', ...zids]);
                    mapInstance.setFilter('houses-highlighted', ['in', 'zid', ...zids]);
                    mapInstance.setLayoutProperty('houses-highlighted', 'visibility', 'visible');

                }




            } else {
                mapInstance.setPaintProperty(layerName, 'circle-opacity', 0.0)
                // set stroke opacity to 0.5 - this is a PAINT property
                mapInstance.setPaintProperty(layerName, 'circle-stroke-opacity', 0.0)
            }

        }

        if (activeResponse !== undefined){
            
            if(scrollflyto[activeResponse.index].center){
                // if coordinated are defined we are flying
                // console.log("flying");
                mapInstance.flyTo(scrollflyto[activeResponse.index]);
            }
            
        }
        



    }, [activeResponse, mapInstanceInit, selectedIds, isLoading])


    useEffect(() => {
        // If map init has not yet occurred then do nothing
        if (!mapInstance) return;
        // if the map is still loading the style url, do nothing
        if (isLoading) return;

        // close previous popup
        if(displayIdPopup && displayIdPopup.isOpen()) {
            // console.log("REMOVING THE POPUP");
            displayIdPopup.remove();
        }

        
        if( displayIds !== undefined && displayIds.length > 0){

            // console.log("DISPLAY IDS", displayIds)
            // const features = mapInstance.queryRenderedFeatures(
            //     {
            //         layers: ['houses-sold-2022-09-17-tileset'],
            //         filter: ['in', 'zid', ...displayIds]
            //     }
            // );

            let features = mapInstance.querySourceFeatures('house-geodata',
            {
                sourceLayer: 'houses-sold-2022-09-17-tileset',
                filter: ["==", "zid", displayIds[0]]
            });

            // This should always return a feature


            // only one popup is displayed
            const feature = features[0];
            // console.log("DISPLAY FEATURE", feature, displayIds, mapInstance);

            //                         <p>zid:${feature.properties.zid}</p> 

            if(feature !== undefined && feature.properties !== undefined)
            {
                const displaySaleDate = feature.properties.saledate ? feature.properties.saledate : "";
                const displayPopup = new mapboxgl.Popup({offset: [0, -15]})
                    .setLngLat(feature.geometry.coordinates)
                    .setHTML(
                        `<p>Date Sold:${displaySaleDate}</p>
                        <p>Pending Price: $${feature.properties.pending_price}</p>
                        <p>Address: ${feature.properties.addressStreet},</p>
                        <p> ${feature.properties.addressCity}, ${feature.properties.addressState}</p>
    
                        `
                    )
                    .addTo(mapInstance);

                    // console.log("DISPLAY POPUP RESULT", displayPopup);
                    setDisplayIdPopup(displayPopup);
            }
        }
        



    }, [mapInstance, displayIds])

    // <div ref={mapContainer}  className="map-container" style={{height: mapHeightString}} />

    return (<>
            <div className={className}>
                <div className={composedStyle}>
                    <div ref={mapContainer}  className="map-container" style={{height: "100vh"}} />
                </div>
            </div>
            </>
    );
}
