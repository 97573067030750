import React from 'react'

import { motion } from "framer-motion";
import { css } from '@emotion/css'

const tailwindStyle = css`
    @tailwind base;
    @tailwind components;
    @tailwind utilities;
`;

const FireshipStyle = css`

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%; // TODO ERR THIS IS WRONG it needs to go over full scroll area
  width: 100%;
  background: #000000BF;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
}

`;

const composedStyle = css`
    ${tailwindStyle};
    ${FireshipStyle};
`;


/**
 * 
 * @param onClick Update global state when backdrop is clicked
 */
const Backdrop = ({children , onClick}) => {

    return (
    <div id="backdrop-style" className={composedStyle}>
        <motion.div
            className="backdrop"
            onClick={onClick}

        >
            {children}
        </motion.div>
    </div>
    )


}

export default Backdrop;