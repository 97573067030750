import React, { useEffect, useMemo } from 'react'
import { css } from '@emotion/css'
import tw, { GlobalStyles } from "twin.macro"

import * as d3 from "d3"
import { d3arrayNS } from "../../d3/d3-array/index.d"



import { HousingIDObject } from '../../model/housingPrice';
import { XAxisDiscrete, YAxis } from '../ChartItems/Axis'
import { useChartDimensions } from '../ChartItems/useChartDimensions'
import { VertBoxPlots } from '../ChartItems/BoxPlotItems/VertBoxPlots'

import { usePhoneHeight } from '../ChartItems/hooks/usePhoneHeight'

const tailwindStyle = css`
@tailwind base;
@tailwind components;
@tailwind utilities;
`;

const BoxStyle = css`



.histogram {
    width: 380px;
}

`;


const composedStyle = css`
    ${tailwindStyle};
    ${BoxStyle};
`;



// This is a utility function that should be pulled out into sep file
// https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript

// TODO this regex might not work with safari

// function numberWithCommas(x) {
//     return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
// }


  // This is a utility function that should be pulled out into sep file
  function median(values: number[]){
    // console.log("values", values);
    const sortedValues = values.sort((a,b) => a-b);
  
    var half = Math.floor(values.length / 2);
    
    if (values.length % 2)
      return values[half];
    
    return (values[half - 1] + values[half]) / 2.0;

  }

  function calcMedian(prices: number[]) {

    if (prices.length === 0) {
        return 0;    
    } else {
        // console.log("LENGTH not zero")
        return median(prices)
    }

}

interface DomainRange {
  range: number[]
}

const bins = (domainRange: number[], ticks:number) => {
  if (domainRange.length !=2) return;
  // console.log("test")

} 

type CombinedProps = IBoxProps ;

interface IBoxProps {
    className: string;
    data: HousingIDObject[];
    width: number;
    height: number;
    fill: string;
    maxY: number;
    handleSelectedIds: React.Dispatch<React.SetStateAction<string[]>>; // fcn to set ids
    dataElementName: string;
    groupByName: string
  }





export const GenericBox: React.FC<CombinedProps> = ({className, children, data, width, height, maxY, fill, 
    dataElementName, groupByName, handleSelectedIds }) => {

    const [chartHeight] = usePhoneHeight(height);

    const chartSettings = {
      "width": width,
      "marginLeft": 30,
      "marginRight": 30,
      "marginTop": 60,
      "marginBottom": 30
    }
    const [ref, dms] = useChartDimensions(chartSettings);
    // console.log("DIMENSIONS", dms)

    const xDomain : any= ["1", "2", "3", "4", "5", "6"];
    const xRange: d3arrayNS.FixedLengthArray<[number, number]> = [0, dms.boundedWidth ];

    // You have to use scaleBand for discrete
    const xScale = d3.scaleBand()
      .domain(xDomain)
      .range(xRange);


    const xTicks = () => {

        const width = xRange[1] - xRange[0];

        const numberOfTicksTarget = xDomain.length;

        // ticks returns a list of numbers

        return xDomain.map(value => {
            
            let xOffset;
            if (xScale(value) !== undefined)
            {
                xOffset = xScale(value);
            } else {
                console.error("Error - plotting value without corresponding xvalue");
            }

            return {
            value, 
            xOffset: xOffset + graphXAdjustment
            }
        })

    }

    const yDomain : d3arrayNS.FixedLengthArray<[number, number]> = [0,maxY];
    // There needs to be a margin with the yrange
    const yRange : d3arrayNS.FixedLengthArray<[number, number]> = [Number(dms.boundedHeight), 0];

    const yScale = d3.scaleLinear()
      .domain(yDomain)
      .range(yRange);

    // console.log("xscale", xScale);
    // console.log("yscale", yScale);

    
    let flatData = data.map(d => {
        let returnObject = {"zid": d.node.zid};
        returnObject[dataElementName] = d.node[dataElementName];
        returnObject[groupByName] = d.node[groupByName]
        return returnObject;
      });

    // Because your domain is determined at this level, your BINS have to be determined at this level
    // Append to JSON object
    
    // create an empty array for each domain value 
    // https://stackoverflow.com/questions/54789406/convert-array-to-object-keys
    const bins = xDomain.reduce((acc,curr)=> (acc[curr]=[],acc),{});

    // console.log("flatData", flatData);
    // console.log("box bins", bins);

    // assign data object to the bins
    flatData.map((dataObject) => {
      // console.log("first data boject")
      let key: string = dataObject[groupByName].toString();
      
      // only plot the information where beds are displayed
      if(bins[key] !== undefined){
        bins[key].push(dataObject)
      }
      
     } )

    // console.log("FILLED bins", bins);

    // MUST be the same for the plot and the XAxisDiscrete
    const graphXAdjustment = 20;

    return (
      <>
      <div className={className} >
        <GlobalStyles />
        <div ref={ref} className="Chart_wrapper"  style={{ height: `${chartHeight}px` }}>
          <svg width={dms.width} height={dms.height}>
            <g transform={`translate(${[
                0,
                0,
              ].join(",")})`}>
                  <YAxis
                    yScale={yScale}
                    domain={yDomain}
                    range={yRange}
                  />
            </g>
            <g transform={`translate(${[
                dms.marginLeft -3,
                0
              ].join(",")})`}>
                <VertBoxPlots
                  bins={bins}
                  width={dms.boundedWidth}
                  height={dms.boundedHeight}
                  xScale={xScale}
                  yScale={yScale}
                  initFillColor={fill}
                  dataElementName={dataElementName}
                  groupByName={groupByName}
                  handleSelectedIds={handleSelectedIds}
                  graphXAdjustment={graphXAdjustment}
                />
            </g>
            {/* change 2nd 0 to dms.boundedHeight */}
            <g transform={`translate(${[
                dms.marginLeft -3,
                dms.boundedHeight,
              ].join(",")})`}>
                <XAxisDiscrete
                  xScale={xScale}
                  domain={xDomain}
                  range={xRange}
                  graphXAdjustment={graphXAdjustment}
                  ticks={xTicks()}
                  additionalXOffset={-2.5}
                  fontSize="1em"
                />
            </g>
          </svg>
        </div>
      </div>
      </>
    )
}
