import React, { useRef, useEffect, useState } from 'react'

import { ResizeObserver } from '@juggle/resize-observer'

// Code suggests you may have to observe width and height

export interface IDimensions {
    width?: number;
    height?: number;
    marginTop?: number;
    marginRight?: number;
    marginBottom?: number;
    marginLeft?: number;
}

interface IBoundedDimensions {
    boundedHeight: number;
    boundedWidth: number;
}

export interface IReturnDimensions extends IDimensions, IBoundedDimensions {}


const defaultIfUndefined = (mainValue: any, defaultValue: any) => {
    if (mainValue !== undefined){
        return mainValue;
    } else {
        return defaultValue;
    }
}

export const combineChartDimensions = (dimensions: IDimensions): IReturnDimensions => {
    

    // Deconstruct dimensions and put in defaults for margins if not provided
    const parsedDimensions = {
        ...dimensions,
        marginTop: defaultIfUndefined(dimensions.marginTop, 10),
        marginRight: defaultIfUndefined(dimensions.marginRight, 10),
        marginBottom: defaultIfUndefined(dimensions.marginBottom, 40),
        marginLeft: defaultIfUndefined(dimensions.marginLeft, 75)
    };

    let boundedHeight;
    if (parsedDimensions.height === undefined){
        boundedHeight = 0;
    } else {
        boundedHeight = Math.max(
            parsedDimensions.height
            - parsedDimensions.marginTop
            - parsedDimensions.marginBottom,
            0,
          );
    }

    let boundedWidth;
    if (parsedDimensions.width === undefined){
        boundedWidth = 0;
    } else {
        boundedWidth = Math.max(
            parsedDimensions.width
            - parsedDimensions.marginLeft
            - parsedDimensions.marginRight,
            0,
        );
    }

    return {
        ...parsedDimensions,
        boundedHeight: boundedHeight,
        boundedWidth: boundedWidth
    }


}


export const useChartDimensions = (passedSettings: IDimensions) : [any, any] => {

    // create a reference that can be used to keep track of the chart
    const ref = useRef();
    const dimensions = combineChartDimensions(
        passedSettings
      );

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    // UseEffect gets the width and height when they are not provided.

    useEffect(() => {
        // Return is what you do when you are disengaging from the effect
        // Because of this, you should only IGNORE effect if you have width and height

        // if (dimensions.width && dimensions.height)
        // return [ref, dimensions]


        if (dimensions.width && dimensions.height) return;
    

        const element = ref.current;
        const resizeObserver = new ResizeObserver((entries, observer) => {
            // If there are no entries, exit out of this function. 

            // console.log('Chart has resized!');
            if (!Array.isArray(entries)) return;
            if (!entries.length) return;

            const entry = entries[0];

            if (width != entry.contentRect.width)
            {
                setWidth(entry.contentRect.width)
            }
            if (height != entry.contentRect.height){
                setHeight(entry.contentRect.height)  
            }


        });

        if(element !== undefined){
            resizeObserver.observe(element);
        }
        

        return () => {
            if(element !== undefined){
                resizeObserver.unobserve(element);
            }
        }
    }, []);

    // todo change this
    const newSettings = combineChartDimensions({
        ...dimensions,
        width: dimensions.width || width,
        height: dimensions.height || height,
    })

    return [ref, newSettings];
}