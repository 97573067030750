import {slice} from "./array";
import bisect from "./bisect";
import constant from "./constant";
import extent from "./extent"
import identity from "./identity"
import nice from "./nice"
import ticks, {tickIncrement} from "./ticks";
import sturges from "./threshold/sturges"

// TODO - you need to run all of the tests off of this to make sure it works consistently

export default function bin() {

    var value = identity;
    var domain = extent;
    var threshold = sturges;

    /**
    * Returned from bin. It takes data, the element that has the id and the value that has bin value
    * @param {number[]} data
    * @param {string} binValue element in json that has the values
    * 
    */
    function histogram(data, binValue?: string){
        // Test to see if you are working with an array

        if(!Array.isArray(data)){
             data = Array.from(data);
        }

        var i,
        n = data.length,
        x,
        step,
        values = new Array(n);

        // Iteration over the entire array and return only the value in the array 
        for (i = 0; i < n; ++i) {
            // RAB the only modification is where you do bin value
            if(binValue !== undefined){
                values[i] = value(data[i][binValue]);
            } else {
                // If the bin value is not declared, you can only go with assuming one number per element
                values[i] = value(data[i]);
            }
            
        }

        // console.log("VALUES", values);
    
        // domain is going to get the minimum and max for the values (does not need id)
        var xz = domain(values, undefined),
        x0 = xz[0],
        x1 = xz[1],
        tz = threshold(values);

        // Convert number of thresholds into uniform thresholds, and nice the
        // default domain accordingly.
        if (!Array.isArray(tz)) {
            const max = x1, tn = +tz;
            if (domain === extent) [x0, x1] = nice(x0, x1, tn);
            tz = ticks(x0, x1, tn);
    
            // If the domain is aligned with the first tick (which it will by
            // default), then we can use quantization rather than bisection to bin
            // values, which is substantially faster.
            if (tz[0] <= x0) step = tickIncrement(x0, x1, tn);
    
            // If the last threshold is coincident with the domain’s upper bound, the
            // last bin will be zero-width. If the default domain is used, and this
            // last threshold is coincident with the maximum input value, we can
            // extend the niced upper bound by one tick to ensure uniform bin widths;
            // otherwise, we simply remove the last threshold. Note that we don’t
            // coerce values or the domain to numbers, and thus must be careful to
            // compare order (>=) rather than strict equality (===)!
            if (tz[tz.length - 1] >= x1) {
            if (max >= x1 && domain === extent) {
                const step = tickIncrement(x0, x1, tn);
                if (isFinite(step)) {
                if (step > 0) {
                    x1 = (Math.floor(x1 / step) + 1) * step;
                } else if (step < 0) {
                    x1 = (Math.ceil(x1 * -step) + 1) / -step;
                }
                }
            } else {
                tz.pop();
            }
            }
        }

        // Remove any thresholds outside the domain.
        var m = tz.length;
        while (tz[0] <= x0) tz.shift(), --m;
        while (tz[m - 1] > x1) tz.pop(), --m;

        var bins = new Array(m + 1),
            bin;

        // Initialize bins.
        for (i = 0; i <= m; ++i) {
            bin = bins[i] = [];
            bin.x0 = i > 0 ? tz[i - 1] : x0;
            bin.x1 = i < m ? tz[i] : x1;
        }

        // Assign data to bins by value, ignoring any outside the domain.
        if (isFinite(step)) {
            if (step > 0) {
                for (i = 0; i < n; ++i) {
                    if ((x = values[i]) != null && x0 <= x && x <= x1) {
                        bins[Math.min(m, Math.floor((x - x0) / step))].push(data[i]);
                    }
                }
            } else if (step < 0) {
                for (i = 0; i < n; ++i) {
                    if ((x = values[i]) != null && x0 <= x && x <= x1) {
                        const j = Math.floor((x0 - x) * step);
                        bins[Math.min(m, j + (tz[j] <= x))].push(data[i]); // handle off-by-one due to rounding
                    }
                }
            }
            } else {
            for (i = 0; i < n; ++i) {
                if ((x = values[i]) != null && x0 <= x && x <= x1) {
                bins[bisect(tz, x, 0, m)].push(data[i]);
                }
            }
        }

        return bins;
    }

    histogram.value = function(_) {
        return arguments.length ? (value = typeof _ === "function" ? _ : constant(_), histogram) : value;
      };

    histogram.domain = function(_) {
        return arguments.length ? (domain = typeof _ === "function" ? _ : constant([_[0], _[1]]), histogram) : domain;
    };

    histogram.thresholds = function(_) {
        return arguments.length ? (threshold = typeof _ === "function" ? _ : Array.isArray(_) ? constant(slice.call(_)) : constant(_), histogram) : threshold;
      };
      
    return histogram;
}