import React, { useEffect, useRef, useState } from 'react'
import helper from '../../../../../../../../utils/helper';

const MedianPendingPricePython: React.FC<{className: string}> = ({className}) => {

    // This contains CSS from PythonStyle above. If this component is moved, that CSS must also move. 

    useEffect(() =>{
        // https://github.com/gerrgg/gregbastianelli showed how to highlight after html code injection
        helper.highlightCode();

        // root color base has to be removed from root so that black does not show on right margin in iphone% and small display phones
        // another change

    });

    // What is the defined width of this before determining scrolling 

    const chartCode = (
<pre tw="mt-3 "><code tw="h-auto whitespace-pre inline-block pt-8 text-xs md:text-base " className="hljs language-python whiteowlpython">

import pandas as pd{"\n"}
# read in the supplied data file {"\n"}
path="./dfw-aug-2022-house-data.tsv"{"\n"}
mergedf = pd.read_csv(path, sep="\t"){"\n"}
{"\n"}
# The data file contains info on rentals{"\n"}
# Exclude the rental data {"\n"}
histdf = mergedf[mergedf['is_for_rent'] == "False"]{"\n"}

# We are not looking at apartments{"\n"}
# or vacant land to determine{"\n"}
# the price of single family home. {"\n"}
histdf = histdf[~histdf['home_type'].isin(['LOT', 'APARTMENT', 'MULTI_FAMILY'])]{"\n"}
{"\n"}
histdf['pending_price'].mean(){"\n"}
# 484881.5379241517{"\n"}

</code></pre>)

    return (
        <>
        {chartCode}
        </>);
}

export default MedianPendingPricePython; 