import React, { useEffect, useState, useRef, useMemo } from 'react'
import { Bar } from '../Bar'


interface VBarProps {
    bins: any;
    yScale: (value:any) => number;
    handleSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
    width: number;
    height: number;
    initFillColor: string;
    ticks: {value: string, xOffset: number}[];
    dataElementName?: string ; // if undef, bars represent counts
    suggestedBarWidth: number;
    graphXAdjustment: number;

}


export const VertBarsDiscrete: React.FC<VBarProps> = ({children, bins, width, height, 
    yScale, initFillColor, handleSelectedIds, ticks, suggestedBarWidth,
    dataElementName, graphXAdjustment }) => {


    useEffect(() => {
        handleSelectedIds([]);
      },[])


    const dms = { width: `${width}px`, height: `${height}px`}
    const maxBarWidth: number = Math.floor(width / ticks.length);
    const barWidth: number = Math.min(suggestedBarWidth, maxBarWidth);


    function drill(data){

        let zids: string[] = [];

        for (var index in data){
            // Only add the id if it exists
            if( data[index].zid !== undefined) {
                zids.push( data[index].zid as string);
            };
            
        }
        //console.log("SELECTED ZIDS", zids);
        handleSelectedIds(zids);
    }

    // bins is a json object
    // push to an array that will be displayed

    let bars: JSX.Element[] = [];

    let i = 0;
    for (var key in bins){
        let attrHeight = 0;
        if(dataElementName === undefined){
            attrHeight = height - yScale(bins[key].length);
        } else {
            console.error("Component has not implemented anything other than count.")
        }

        // TODO - not sure why the -1 is here
        let attrWidth = barWidth -1;

        // TODO - you really need to double check if this is the right start
        let xStart = ticks[i]['xOffset'] - (attrWidth/2) + graphXAdjustment;
        
        
        // update counter
        let bar =  (<>

            <Bar
                key={key}
                attrHeight={attrHeight}
                attrWidth={attrWidth}
                translateXVal={xStart}
                translateYVal={yScale(bins[key].length)}
                initFill={initFillColor}
                d={bins[key]}
                handleSelectedIds={handleSelectedIds}
            />
            
            </>
        );

        bars.push(bar);

        i = i + 1 ;
        
    }


    return(
        <>
        <svg width={dms.width} height={dms.height}>

        {bars}

        </svg>
        </>
    )
 }
