import React, { useEffect, useState, useRef, useMemo } from 'react'


interface BarProps {
    attrHeight: number
    attrWidth: number
    translateXVal: number
    translateYVal: number
    initFill: string
    d: any
    handleSelectedIds: React.Dispatch<React.SetStateAction<string[]>>
}

const getZidStringsFromData = (data) : string[] => {
    let zids: string[] = [];

    for (var index in data){
        // Only add the id if it exists
        if( data[index].zid !== undefined) {
            zids.push( data[index].zid as string);
        };
        
    }

    return zids;
}

export const Bar: React.FC<BarProps> = ({children, attrWidth, attrHeight, translateXVal, translateYVal, initFill, d, handleSelectedIds  }) => {

    const [selected, setSelected] = useState(false);

    let fillValue = initFill;
    if(selected){
        fillValue = "#FFC015"
    } 

    function drill(data){
        
        let zidStrings = getZidStringsFromData(data); 

        // If current state is selected, remove data
        if(selected) {
            // Bar is selected and it is about to be UNSELECTED
            // console.log("BOX IS ABOUT to be UNSELECTED");
            handleSelectedIds((prevState) => {
                    const remainingZids: string[] = prevState.filter(n => !zidStrings.includes(n))
                    return remainingZids
                }
            );

        } else
        {
            // console.log("BOX is about to be selected");
            // add data in a non dup way
            handleSelectedIds((prevState) => {
                // let concatArray = prevState.concat(zidStrings);
                let noDuplicateArray = [...new Set([...prevState,...zidStrings])]
                return noDuplicateArray});

        
        }



     

        // update selected to new state
        setSelected((prevState) => !prevState)



        // If current state is NOT selected, then add data

        // Additional code is needed to handle selected IDS

    }



    return (
        <>
            <rect
            transform={`translate(${translateXVal}, ${translateYVal} )`}
            width={attrWidth}
            height={attrHeight}
            fill={fillValue}
            onClick={(e) => drill(d)}
        />
        </>
    )

}

