import React, { useEffect, useRef, useState } from 'react'
import helper from '../../../../../../../../utils/helper';

const PSFHistPython: React.FC<{className: string}> = ({className}) => {

    // This contains CSS from PythonStyle above. If this component is moved, that CSS must also move. 

    useEffect(() =>{
        // https://github.com/gerrgg/gregbastianelli showed how to highlight after html code injection
        helper.highlightCode();

    });

    const chartCode = (
<pre tw="mt-3 "><code tw="h-auto whitespace-pre inline-block pt-8 text-xs md:text-base " className="hljs language-python whiteowlpython">

price = 0{"\n"}
price_list = [0]{"\n"}
increment = 50{"\n"}
while price {"<"} 500:{"\n"}
    price = price + increment{"\n"}
    price_list.append(price){"\n"}
{"\n"}
histogram('psf', price_list){"\n"}

</code></pre>)

    return (
        <>
        {chartCode}
        </>);
}

export default PSFHistPython;