

export const getNewDate = () => {
    const localDate = new Date();
    const UTCYear = localDate.getUTCFullYear().toString();
    const UTCMonth = localDate.getUTCMonth().toString();
    const UTCDate = localDate.getUTCDate().toString();
    const UTCHours = localDate.getUTCHours().toString();
    const UTCMinutes = localDate.getUTCMinutes().toString();
    const UTCSeconds = localDate.getUTCSeconds().toString();
    const UTCMilliseconds = localDate.getUTCMilliseconds().toString();
    const ISOString = localDate.toISOString();

    return {UTCYear, UTCMonth, UTCDate, UTCHours, UTCMinutes, UTCSeconds, UTCMilliseconds, ISOString}


}