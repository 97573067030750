import React, { useEffect, useState, useMemo } from 'react'
import { css } from '@emotion/css'
import tw, { GlobalStyles } from "twin.macro"

import * as d3 from "d3"
import * as myd3 from "../../d3/d3-array" 
import { d3arrayNS } from '../../d3/d3-array/index.d'

import { HousingIDObject } from '../../model/housingPrice';
import { XAxis, YAxis } from '../ChartItems/Axis'
import { VertBars } from '../ChartItems/VertBars'
import { useChartDimensions } from '../ChartItems/useChartDimensions'

import { usePhoneHeight } from '../ChartItems/hooks/usePhoneHeight'

const tailwindStyle = css`
@tailwind base;
@tailwind components;
@tailwind utilities;
`;

const HistogramStyle = css`



.histogram {
    width: 380px;
}

`;


const composedStyle = css`
    ${tailwindStyle};
    ${HistogramStyle};
`;



// This is a utility function that should be pulled out into sep file
// https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript

// TODO this regex might not work with safari

// function numberWithCommas(x) {
//     return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
// }


  // This is a utility function that should be pulled out into sep file
  function median(values: number[]){
    // console.log("values", values);
    const sortedValues = values.sort((a,b) => a-b);
  
    var half = Math.floor(values.length / 2);
    
    if (values.length % 2)
      return values[half];
    
    return (values[half - 1] + values[half]) / 2.0;

  }

  function calcMedian(prices: number[]) {

    if (prices.length === 0) {
        return 0;    
    } else {
        // console.log("LENGTH not zero")
        return median(prices)
    }

}

interface DomainRange {
  range: number[]
}

const bins = (domainRange: number[], ticks:number) => {
  if (domainRange.length !=2) return;
  // console.log("test")

} 

type CombinedProps = IHistogramProps ;

interface IHistogramProps {
    className: string;
    data: HousingIDObject[];
    width: number;
    height: number;
    fill: string;
    maxX: number;
    maxY: number;
    handleSelectedIds: React.Dispatch<React.SetStateAction<string[]>>; // fcn to set ids
    dataElementName: string;
  }

export const GenericHist: React.FC<CombinedProps> = ({className, children, data, width, height, maxX, maxY, fill, dataElementName, handleSelectedIds }) => {

  const [chartHeight] = usePhoneHeight(height);

    const chartSettings = {
      "width": width,
      "marginLeft": 30,
      "marginRight": 30,
      "marginTop": 60,
      "marginBottom": 30
    }
    const [ref, dms] = useChartDimensions(chartSettings);
    // console.log("DIMENSIONS", dms)


    const xDomain : d3arrayNS.FixedLengthArray<[number, number]> = [0, maxX];
    const xRange: d3arrayNS.FixedLengthArray<[number, number]> = [0, dms.boundedWidth ];

    const xScale = d3.scaleLinear()
      .domain(xDomain)
      .range(xRange);
  
    const yDomain : d3arrayNS.FixedLengthArray<[number, number]> = [0,maxY];
    // There needs to be a margin with the yrange
    const yRange : d3arrayNS.FixedLengthArray<[number, number]> = [Number(dms.boundedHeight), 0];

    const yScale = d3.scaleLinear()
      .domain(yDomain)
      .range(yRange);

    // console.log("xscale", xScale);
    // console.log("yscale", yScale);

    // TODO - make threshold work with typescript
    const histogram = myd3.bin()
          .domain(xDomain)
          .thresholds(xScale.ticks(10));
    
    let flatData = data.map(d => {
        // return {"zid": d.node.zid , "pending_price": d.node.pending_price}
        // return {"zid": d.node.zid , pending_price: d.node[dataElementName]}

        let returnObject = {"zid": d.node.zid};
        returnObject[dataElementName] = d.node[dataElementName];

        return returnObject;
      });

    // histogram needs the data and the json label containing values
    // for binning. 

    var bins = histogram(flatData, dataElementName);
    //console.log("THE BIN DATA", bins)


   return (
    <>
    <div className={className} >
      <GlobalStyles />
      <div ref={ref} className="Chart_wrapper"  style={{ height: `${chartHeight}px` }}>
        <svg width={dms.width} height={dms.height}>
          <g transform={`translate(${[
              0,
              0,
            ].join(",")})`}>
                <YAxis
                  yScale={yScale}
                  domain={yDomain}
                  range={yRange}
                />
          </g>
          <g transform={`translate(${[
              dms.marginLeft -3,
              0
            ].join(",")})`}>
              <VertBars
                width={dms.boundedWidth}
                height={dms.boundedHeight}
                bins={bins}
                xScale={xScale}
                yScale={yScale}
                initFillColor={fill}
                handleSelectedIds={handleSelectedIds}
              />
          </g>
          {/* change 2nd 0 to dms.boundedHeight */}
          <g transform={`translate(${[
              dms.marginLeft -3,
              dms.boundedHeight,
            ].join(",")})`}>
              <XAxis
                xScale={xScale}
                domain={xDomain}
                range={xRange}
              />
          </g>
        </svg>
      </div>
    </div>
    </>
   )
}
