// Feels like all of the styling that you need should be here

// scrollflyto should ONLY contain the map controls

export const scrollflyto = [
    {
        title: "Step 1- Becoming a Data Scientist: Data Exploration",
        showdots: false,
        bearing: 0,
        center: [-97.6335 , 9.6450],
        zoom: 2.5283393854691716 ,
        pitch: 0.9998094279635839
    },
    {
        title: "Step 2- This is Dallas, Texas",
        showdots: false,
        bearing: 0,
        center: [-96.5052, 30.1512],
        zoom: 5.931777039014436 ,
        pitch: 0
    },
    {
        title: "Step 3- And these are the homes sold",
        showdots: true,
        bearing: 0,
        center: [-96.5052, 30.1512],
        zoom: 5.931777039014436 ,
        pitch: 0
    },
    {
        title: "Step 4 - This is one of the most expensive houses.",
        showdots: true,
        bearing: 0,
        center: [-96.7837 , 32.7961],
        zoom: 12.783889224748686 ,
        pitch: 34.00971917385645
    },
    {
        title: "Step 5 - Cheaper home in Fort Worth",
        showdots: true,
        bearing: -132.28554303679527,
        center: [ -97.2330 , 32.7959],
        zoom: 11.548431811844695 ,
        pitch: 40.38984206800554
    },
    {
        title: "Step 6 - Data collected",
        showdots: true,
        bearing: 0,
        center: [-96.5052, 30.1512],
        zoom: 5.931777039014436 ,
        pitch: 0
    },
    {
        title: "Step 7 - Median Price Python",
        showdots: true,
        bearing: -132.28554303679527,
        center: [ -96.8928  , 32.9657],
        zoom: 11.548431811844695 ,
        pitch: 40.38984206800554
    },
    {
        title: "Step 8 - Generic Hist",
        showdots: true,
        bearing: 0,
        center: [-98.1333 , 32.2920],
        zoom: 7.2548 ,
        pitch: 0
    },
    {
        title: "Step 9 - Pending Price Hist Python",
        showdots: true,
        bearing: 0,
        center: [-98.1333 , 32.2920],
        zoom: 7.2548 ,
        pitch: 0
    },
    {
        title: "STEP 10 - PSF hist",
        showdots: true,
        bearing: 0,
        center: [-98.1333 , 32.2920],
        zoom: 7.2548 ,
        pitch: 0
    },
    {
        title: "STEP 11- PSF hist python",
        showdots: true,
        bearing: 0,
        center: [-98.1333 , 32.2920],
        zoom: 7.2548 ,
        pitch: 0
    },
    {
        title: "STEP 12 - Bed PSF ",
        showdots: true,
        bearing: 0,
        center: [-98.1333 , 32.2920],
        zoom: 7.2548 ,
        pitch: 0
    },
    {
        title: "STEP 13 - Conclusion ",
        showdots: true,
        bearing: 0,
        center: [-98.1333 , 32.2920],
        zoom: 7.2548 ,
        pitch: 0
    },
    {
        title: "STEP 14 - Blank ",
        showdots: true,
        bearing: 0,
        center: [-98.1333 , 32.2920],
        zoom: 7.2548 ,
        pitch: 0
    },


];

