
enum Environment
{
    Dev = "DEV",
    Build = "BUILD",
    QA = "QA",
    Prod = "PROD"
}

// this is add customer exactly the way it was before
export function sendEmailFromTemplate(env :Environment, targetEmail: string,
    templateName: string, senderEmail: string, subject: string) {
        // URL will change because I am updating this
        const api_url = 'https://sendemailv1-tgcg47zmfa-uc.a.run.app';

        const parameters = {

            email: targetEmail,
            senderemail: senderEmail, 
            templatename: templateName,
            subject: subject
        };

        try {
            console.log("BEFORE FETCH")
            fetch(api_url, {
                method: 'POST', 
                mode: 'cors',
                cache: 'no-cache',
                referrerPolicy: 'no-referrer-when-downgrade',
                headers: {
                'Content-Type': 'application/json'
                },
                body: JSON.stringify(parameters),
                keepalive: false
        
            }).then(response => {
                console.log("RESPONSE FROM FB function for email", response);
                response.json().then(data => {
                console.log("RESPONSE", response)

                let status = response.status;

                });
            });
        } catch (err){
            console.log("Send Email from template: Some other error happened in the try block", err)
        } finally {
            // TODO Attempt to delete any test code that was writte to firebase
            console.log("FINALLY BLOCK");
        }

        
};

