const tags = ['Blog']

// TODO - Is article meta going to pull article data as well?
export const siteName = "White Owl Education"

export const blogPost = {
    id: "2022-10-14-00",
    created_at: "2022-10-14T00:00:00.000-00:00",
    updated_at: "2022-10-14T00:00:00.000-00:00",
    page: {
        url: "https://www.whiteowleducation.com/blog/2022/10/14/real-estate-data-exploration/"
    },
    author: {
        type: "Person",
        name: "White Owl Education",
        author_image_url: "https://whiteowleducation.com/images/authors/white-owl-education/white-owl-education-author-face.jpg",
        pathname: "images/authors/white-owl-education/white-owl-education-author-face.jpg",
        author_image_description: "Picture of Ralph Brooks, CEO of White Owl Education"
    },
    title: "Become a Data Scientist: Interactive Data Exploration",
    publisher: {
        name: siteName,
        url: "https://whiteowleducation.com/images/theme/header/logo/wo-logo-transparent.png"
    },
    description: "Get started becoming a data scientist by learning how to explore real estate sales in this interactive tutorial.",
    image: {
        url: "https://whiteowleducation.com/images/blog/2022/10/14/real-estate-main-image.png",
        description: "Interactive tutorial of data exploration using August 2022 real estate sales data from Dallas."
    },
    internal_featured_image: {
        pathname: "images/blog/2022/10/14/real-estate-main-image.png",
        description: "Interactive tutorial of data exploration using August 2022 real estate sales data from Dallas."
    },
    keywords: tags[0],
    featured: "no",
    read_time_minutes : "10",
    visibility: "public"
}