
import React, { useEffect, useState, useRef, useMemo } from 'react'
import { css } from '@emotion/css'
import * as d3 from "d3"

import { d3arrayNS } from "../../d3/d3-array/index.d"
import { text } from '@fortawesome/fontawesome-svg-core'


// Rebuild of the chart solves the onclick problem


export const XAxis: React.FC<d3arrayNS.IXAxisProps> = ({children, xScale, domain, range }) => {

    // function changes if the upper or the lower of domain
    // or range change


    const ticks = useMemo(() => {

        const width = range[1] - range[0];

        // How does this apply 
        const pixelsPerTick = 30;
        const numberOfTicksTarget = Math.max(
            1, 
            Math.floor(width / pixelsPerTick)
        );

        // a similar thing is done here for yScale

        // ticks returns a list of numbers

        return xScale.ticks(numberOfTicksTarget)
            .map(value => ({
                value, 
                xOffset: xScale(value)
            }))
    }, [
        domain.join("-"),
        range.join("-")
    ]);

    // d defines the path that is to be drawn
    // M - moveto x, y = range[0] is the first element of range
    // v - draw a vertical line from 

    // Each tick contains a value and an offset

    // Add a duplicate first and last tick mark

    // y gets bigger as you go down

    return (
        <svg>

            <path
                d={[
                    "M", range[0], 6,
                    "v", -6,
                    "H", range[1],
                    "v", 6,

                ].join(" ")}
                fill="none"
                stroke="currentColor"
            />
            {ticks.map(({value, xOffset }) => (
                    // translate is movement by x and Y
                    // put 0, $yOffset for y
                    <g
                        key={value}
                        transform={`translate(${xOffset},0) `}
                    >
                        {/* vert line - x is x2 */}
                        {/* I think y2 is where it ends */}
                        <line 
                            y2="6"
                            stroke="currentColor"
                        />
                        <text
                            key={value}
                            style={{
                                stroke: "currentcolor",
                                fontSize: "8px",
                                textAnchor: "middle",
                                transform: "translateY(30px) "
                            }}
                        >
                            {value}
                        </text>


                    </g>
                )
            )}
        </svg>
    )
}




export const XAxisDiscrete: React.FC<d3arrayNS.IXAxisDiscreteProps> = ({children, xScale, domain, range, graphXAdjustment, 
    ticks, additionalXOffset, fontSize }) => {


    /// Ticks and domain mapping is different when dealing with discrete values

 
    // set tickvalues for other parts of the chart that may need it
    const tickOffsets : number[] = [];

    ticks.forEach(function(tick){
        tickOffsets.push(tick.xOffset)
    });

      


    // d defines the path that is to be drawn
    // M - moveto x, y = range[0] is the first element of range
    // v - draw a vertical line from 

    // Each tick contains a value and an offset

    // Add a duplicate first and last tick mark

    // y gets bigger as you go down

    const textXOffsetBaseline: number = 2
    let textXOffset = textXOffsetBaseline + additionalXOffset
    let textXOffsetString: string = textXOffset.toString() +"em"
    // console.log("TEXT OFFSET", textXOffsetString);

    return (
        <svg>

            <path
                d={[
                    "M", range[0], 6,
                    "v", -6,
                    "H", range[1],
                    

                ].join(" ")}
                fill="none"
                stroke="currentColor"
            />
            {ticks.map(({value, xOffset }) => (
                    // translate is movement by x and Y
                    // put 0, $yOffset for y
                    <g
                        key={value}
                        transform={`translate(${xOffset},0) `}
                    >
                        {/* vert line - x is x2 */}
                        {/* I think y2 is where it ends */}
                        <line 
                            y2="6"
                            stroke="currentColor"
                        />
                        <text
                            key={value}
                            style={{
                                stroke: "currentcolor",
                                fontSize: fontSize,
                                textAnchor: "middle",
                                transform: `translateX(${textXOffsetString}) translateY(50px) rotate(45deg)`
                            }}
                        >
                            {value}
                        </text>


                    </g>
                )
            )}
        </svg>
    )
}



// Y Axis 

const YAxisStyle = css`

// mobile

.yaxis-ticks-text {
    color: currentcolor;
    font-size: 10px;
    transform: "translateX(-10px) "
    }

// sm 
@media (min-width: 640px) {
.yaxis-ticks-text {
    color: currentcolor;
    font-size: 12px;
    transform: "translateX(0px)"
    }
}


`;


export const YAxis: React.FC<d3arrayNS.IYAxisProps> = ({children, domain, range, yScale }) => {

    // function changes if the upper or the lower of domain
    // or range change


    // This is a positive number
    const height =  range[0] - range[1];
    const heightpx = `${height}px`;
    // console.log("height y axis", height);

    const ticks = useMemo(() => {



        // How does this apply 
        const pixelsPerTick = 30;
        const numberOfTicksTarget = Math.max(
            1, 
            Math.floor(height / pixelsPerTick)
        );

        // a similar thing is done here for yScale

        // ticks returns a list of numbers

        return yScale.ticks(numberOfTicksTarget)
            .map(value => ({
                value, 
                yOffset: yScale(value)
            }))
    }, [
        domain.join("-"),
        range.join("-")
    ]);

    // d defines the path that is to be drawn
    // M - moveto x, y = range[0] is the first element of range
    // v - draw a vertical line from 

    // Each tick contains a value and an offset

    // Add a duplicate first and last tick mark

    // y gets bigger as you go down

    //console.log("Y Ticks", ticks);

    const xOffset = 20;

    return (
        <svg className={YAxisStyle} width="300px" height={heightpx}>
            {/* range 0 is height - which is the bottom of the chart */}
            the path is NOT relative to the bar
            <g 
                key="bar"
                transform={`translate(${xOffset}, 0)`}
            >
                <path
                    d={[
                        "M", 0, range[0],
                        "h", 6,
                        "V", range[1],
                        "h", -6,

                    ].join(" ")}
                    fill="none"
                    stroke="currentColor"
                />
            </g>
            {ticks.map(({value, yOffset }) => (
                    // translate is movement by x and Y
                    // put 0, $yOffset for y
                    // transform={`translate(${xOffset}, ${yOffset}) `}
                    // transform={`translate(0, ${yOffset }) `}
                    <g
                        key={value}
                    >
                        {/* vert line - x is x2 */}
                        {/* I think y2 is where it ends */}
                        <line 
                            x1="6"
                            stroke="currentColor"
                            transform={`translate(20, ${yOffset }) `}
                        />
                        <text
                            className="yaxis-ticks-text"
                            key={value}
                            style={{
                                stroke: "currentcolor",
                            }}
                            transform={`translate(0, ${yOffset}) `}
                        >
                            {value}
                        </text>


                    </g>
                )
            )}
        </svg>
    )
}