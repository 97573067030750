import React, { useEffect, useState, useRef } from 'react'
import { css } from '@emotion/css'
import tw, { GlobalStyles } from "twin.macro"

const tailwindStyle = css`
@tailwind base;
@tailwind components;
@tailwind utilities;
`;



const SpinnerStyle = css`

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  position:fixed;
  top: 0;
  left: 0;
  height: 100vh;
  padding-top: 50vh;
  width: 100vw;
  padding-left:45vw;
  background-color: #252524;
  z-index: 10000;
}

`;


const composedStyle = css`
    ${tailwindStyle};
    ${SpinnerStyle};
`;



export default function LoadingSpinner() {
  return (

      <div className={composedStyle}>
          <GlobalStyles />
          <div className="spinner-container" tw="">
            <div className="loading-spinner"></div>
          </div>
        </div>
    
    );
}