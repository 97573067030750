

export function convertDateStringToObject(dateString: string){
    const dateObject: Date = new Date(dateString);
    return dateObject;
}

/**
 * 
 * @param dateRangeString either "8/29/2022" or "8/20/22-8/21/22" 
 * return an array containing 1 or two dates converted into javascript dates
 */
export function parseDateString(dateRangeString: string): Date[] | null {
    const regExDateRange = /([0-9]+\/[0-9]+\/[0-9]+)-?([0-9]+\/[0-9]+\/[0-9]+)?/;
    const match = dateRangeString.match(regExDateRange);

    if (match == null){
        return null;
    } else {

        if(match[2] === undefined){
            const dateDetected = convertDateStringToObject(match[1]);
            return [dateDetected];
        } else {
            const firstDateDetected = convertDateStringToObject(match[1]);
            const secondDateDetected = convertDateStringToObject(match[2]);
            return [firstDateDetected, secondDateDetected]; 
        }

    }

    // If nothing above was returned, then send back null
    return null;

}

export function convertXDomainToDates(xDomain: string[]):
         ({"domainDateString": string, "extractedDates": Date[] | null })[] {

    let resultArray:  ({"domainDateString": string, "extractedDates": Date[] | null })[] = []
    for (const domainDateString of xDomain){
        const extractedDates = parseDateString(domainDateString);
        const keyValueObject = {"domainDateString": domainDateString, "extractedDates": extractedDates};
        resultArray.push(keyValueObject);
    }

    return resultArray;
}

export function inDateRange( targetDate: Date, dateRangeArray: Date[]| null): boolean | undefined {

    if (dateRangeArray === null || dateRangeArray === undefined || dateRangeArray.length < 1){
        return undefined;
    }

    // Set hours to zero so that you are comparing date without the hour
    targetDate.setHours(0,0,0,0);




    // determine if date is in range
    const greaterThanStart = targetDate >= dateRangeArray[0] 

    let lessThanEnd = false;
    if (dateRangeArray.length == 2){
        lessThanEnd = targetDate <= dateRangeArray[1]; 
    } else if (dateRangeArray.length == 1){
        lessThanEnd = targetDate <= dateRangeArray[0];
    }

    // determine what to return 
    switch(dateRangeArray.length){
        case 1:
            return greaterThanStart && lessThanEnd;
            break;
        case 2:
            return greaterThanStart && lessThanEnd;
            break;
        default:
            return undefined;
    }
        
}

export class BinByDate {

    public xDomain: string[];
    public xDomainDates: ({"domainDateString": string, "extractedDates": Date[] | null })[]

    constructor(xDomainValues: string[]) {
        this.xDomain = xDomainValues;
        this.xDomainDates = convertXDomainToDates(xDomainValues);

    }

    public getDateCategory =(dataObject:any, dateFieldName:string) : string | undefined => {

        // This is a string containing a number
        const pythonDateSoldTS : string = dataObject[dateFieldName]
        const jsDateSold = new Date(Number(pythonDateSoldTS))
        
        for(const keyValueObject of this.xDomainDates){
            if (inDateRange(jsDateSold, keyValueObject.extractedDates )){
                return keyValueObject.domainDateString
            }
        }

        console.error("Dataobject could not be classifed", dataObject, dateFieldName, pythonDateSoldTS, jsDateSold);
        return undefined;
    }
}



