import React, { useState, useEffect } from 'react'
import { css } from '@emotion/css'
import tw, { GlobalStyles } from "twin.macro"

import * as d3 from "d3"
import * as myd3 from "../../d3/d3-array" 
import { d3arrayNS } from "../../d3/d3-array/index.d"



import { DatesCollectedTsvObject, HousingIDObject } from '../../model/housingPrice';
import { XAxisDiscrete, YAxis } from '../ChartItems/Axis'
import { VertBars } from '../ChartItems/VertBars'
import { useChartDimensions } from '../ChartItems/useChartDimensions'
import { VertBoxPlots } from '../ChartItems/BoxPlotItems/VertBoxPlots'
import { BinByDate } from '../ChartItems/HistPlotItems/DateFunctions'
import { VertBarsDiscrete } from '../ChartItems/HistPlotItems/VertBarsDiscrete'

import { usePhoneHeight } from '../ChartItems/hooks/usePhoneHeight'



const tailwindStyle = css`
@tailwind base;
@tailwind components;
@tailwind utilities;
`;

const BoxStyle = css`



.histogram {
    width: 380px;
}

`;


const composedStyle = css`
    ${tailwindStyle};
    ${BoxStyle};
`;



// This is a utility function that should be pulled out into sep file
// https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript

// TODO this regex might not work with safari

// function numberWithCommas(x) {
//     return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
// }








type CombinedProps = IHistogramProps ;

interface IHistogramProps {
    className: string;
    data: HousingIDObject[];
    width: number;
    height: number;
    fill: string;
    maxY: number;
    handleSelectedIds: React.Dispatch<React.SetStateAction<string[]>>; // fcn to set ids
    dataElementName: string;
    groupByName: string
  }





export const GenericHistDiscrete: React.FC<CombinedProps> = ({className, children, data, width, height, maxY, fill, 
    dataElementName, groupByName, handleSelectedIds }) => {

    // chart height adjusts based off of the available height of the phone 
    const [chartHeight] = usePhoneHeight(height);
    

    const chartSettings = {
      "width": width,
      "marginLeft": 30,
      "marginRight": 30,
      "marginTop": 60,
      "marginBottom": 30
    }
    const [ref, dms] = useChartDimensions(chartSettings);
    // // console.log("DIMENSIONS", dms)

    // TODO event listener needs to be passed in from above

  
  
    const xDomain : any= ["8/12/22", "8/13/22-8/14/22", "8/15/22-8/19/22", "8/20/22-8/21/22", "8/22/22-8/26/22", "8/27/22-8/28/22", "8/29/22"]
    const xRange: d3arrayNS.FixedLengthArray<[number, number]> = [0, dms.boundedWidth ];

    // You have to use scaleBand for discrete
    const xScale = d3.scaleBand()
      .domain(xDomain)
      .range(xRange);

    const graphXAdjustment = 20;

    const xTicks = () => {

        const width = xRange[1] - xRange[0];

        const numberOfTicksTarget = xDomain.length;

        // ticks returns a list of numbers

        return xDomain.map(value => {
            
            let xOffset;
            if (xScale(value) !== undefined)
            {
                xOffset = xScale(value);
            } else {
                console.error("Error - plotting value without corresponding xvalue");
            }

            return {
            value, 
            xOffset: xOffset + graphXAdjustment
            }
        })

    }

    // console.log("X Ticks", xTicks())


    

    const yDomain : d3arrayNS.FixedLengthArray<[number, number]> = [0,maxY];
    // There needs to be a margin with the yrange
    const yRange : d3arrayNS.FixedLengthArray<[number, number]> = [Number(dms.boundedHeight), 0];

    const yScale = d3.scaleLinear()
      .domain(yDomain)
      .range(yRange);

    
    let flatData = data.map(d => {
        let returnObject = {"zid": d.node.zid};

        // For this function data element name is not needed
        returnObject[dataElementName] = d.node[dataElementName];
        returnObject[groupByName] = d.node[groupByName]
        return returnObject;
      });

    // // Because your domain is determined at this level, your BINS have to be determined at this level
    // // Append to JSON object
    
    // // create an empty array for each domain value 
    // // https://stackoverflow.com/questions/54789406/convert-array-to-object-keys
    let bins = xDomain.reduce((acc,curr)=> (acc[curr]=[],acc),{});

    const binByDate = new BinByDate(xDomain);

    // // assign data object to the bins
    flatData.map((dataObject) => {


      // console.log("first data boject")
      const key: string | undefined = binByDate.getDateCategory(dataObject, "date_sold");
 
      // only plot the information if you get a valid key and that key is part of the
      // return json object

      if(key !== undefined && bins[key] !== undefined){
        bins[key].push(dataObject)
      }
      
     } );



    
    // console.log("CHART HEIGHT", chartHeight);

    // MUST be the same for the plot and the XAxisDiscrete
    
    // console.log("GRAPH X 2", graphXAdjustment)

    return (
      <>
      <div className={className} >
        <GlobalStyles />
        <div ref={ref} className="Chart_wrapper"  style={{ height: `${chartHeight}px` }}>
          <svg width={dms.width} height={dms.height}>
            <g transform={`translate(${[
                0,
                0,
              ].join(",")})`}>
                  <YAxis
                    yScale={yScale}
                    domain={yDomain}
                    range={yRange}
                  />
            </g>
            <g transform={`translate(${[
                dms.marginLeft -3,
                0
              ].join(",")})`}>
                <VertBarsDiscrete
                  bins={bins}
                  width={dms.boundedWidth}
                  height={dms.boundedHeight}
                  suggestedBarWidth={40}
                  yScale={yScale}
                  initFillColor={fill}
                  handleSelectedIds={handleSelectedIds}
                  graphXAdjustment={0}
                  ticks={xTicks()}
                />
            </g>
            <g transform={`translate(${[
                dms.marginLeft -3,
                dms.boundedHeight,
              ].join(",")})`}>
                <XAxisDiscrete
                  xScale={xScale}
                  domain={xDomain}
                  range={xRange}
                  graphXAdjustment={graphXAdjustment}
                  ticks={xTicks()}
                  additionalXOffset={0}
                  fontSize="0.7em"
                />
            </g>  
          </svg>
        </div>
      </div>
      </>
    )
}
