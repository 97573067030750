
enum Environment
{
    Dev = "DEV",
    Build = "BUILD",
    QA = "QA",
    Prod = "PROD"
}

// this is add customer exactly the way it was before
export function recordCustomerInquiryInFirebase(env :Environment, targetName: string, targetEmail: string,
    targetYear: string, targetMonth: string, targetDay: string, targetHour: string,
    targetMinute: string, targetSecond: string, targetMilliseconds: string, ISOString: string,
    sourceOfInfo: string) {
        // URL will change because I am updating this
        const api_url = 'https://addcustomerinfov1-tgcg47zmfa-uc.a.run.app';
        // const api_url = 'http://localhost:5001/whiteowledpayments/us-central1/addcustomerinfov1';

        const parameters = {
            fullname: targetName,
            email: targetEmail,
            updateyear: targetYear,
            updatemonth: targetMonth,
            updateday: targetDay,
            updatehour: targetHour,
            updateminute: targetMinute,
            updatesecond: targetSecond,
            updatemilliseconds: targetMilliseconds,
            isostring: ISOString,
            sourceofinfo: sourceOfInfo
        };

        try {
            console.log("BEFORE FETCH")
            fetch(api_url, {
                method: 'POST', 
                mode: 'cors',
                cache: 'no-cache',
                referrerPolicy: 'no-referrer-when-downgrade',
                headers: {
                'Content-Type': 'application/json'
                },
                body: JSON.stringify(parameters),
                keepalive: false
        
            }).then(response => {

                response.json().then(data => {
                console.log("RESPONSE", response)

                let status = response.status;

                });
            });
        } catch (err){
            console.log("Add Inquiry: Some other error happened in the try block", err)
        } finally {
            // TODO Attempt to delete any test code that was writte to firebase
            console.log("FINALLY BLOCK");
        }

        
};

