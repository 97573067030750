import React, { useState, useReducer } from "react";
import { css } from '@emotion/css'
import tw, { GlobalStyles } from "twin.macro"


import { motion } from "framer-motion";
import Backdrop from "../Backdrop/Backdrop";
import { recordCustomerInquiryInFirebase } from "../email/callAddCustomer";
import { getNewDate } from "../email/dateFunctions";
import { sendEmailFromTemplate } from "../email/callSendEmail";

const tailwindStyle = css`
    @tailwind base;
    @tailwind components;
    @tailwind utilities;
`;

const ModalStyle = css`

.modal {
    width: clamp(50%, 700px, 90%);
    height: min(50%, 300px);
    margin: auto;
    padding: 0 2rem;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.orange-gradient {
  background: linear-gradient(10deg, #ffaa00, #ff6a00);

}

#no-thanks {
    margin-top: 30px;
}

.close-button {
  border: none;
  display: inline-block;
  padding: 8px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: #261E1C;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  font-size:2em;
}

.topright {
  position: absolute;
  right: 0;
  top: -0.5em;
}

`;

const composedStyle = css`
    ${tailwindStyle};
    ${ModalStyle};
`;


// TODO - when the user clicks modal scrolling needs to stop
// TODO - modal needs to position in the center of the screen


// hidden - hidden at the top of the screen by one screen and make invisible

const dropIn = {

    hidden: {
        y: "-100vh",
        opacity: 0
    },

    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        }

    },
    exit: {
        y: "100vh",
        duration: 10,
        opacity: 0
    }

}

enum Environment
{
    Dev = "DEV",
    Build = "BUILD",
    QA = "QA",
    Prod = "PROD"
}

const formReducer = (state, event) => {
    return {
      ...state,
      [event.name]: event.value
    }
   }

const Modal: React.FC<{className: string,
    handleClose: any,
    text: string,
    modalType: string}> = ({ className, handleClose, text, modalType } ) => {

    const [formData, setFormData] = useReducer(formReducer, {});
    const [submitting, setSubmitting ] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showModal, setShowModal] = useState(true);

    const handleChange = event => {
        setFormData({
          name: event.target.name,
          value: event.target.value,
        });
      }
    

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("START HANDLE TEST");
        setSubmitting(true);
        console.log("FORM DATA for submission", formData)

        const userEmail = formData["emailaddress"];
        console.log("USER EMAIL:", userEmail);

        const {UTCYear, UTCMonth, UTCDate, UTCHours, UTCMinutes, UTCSeconds, UTCMilliseconds, ISOString} = getNewDate();
        recordCustomerInquiryInFirebase("PROD" as Environment, "", userEmail, UTCYear, UTCMonth, UTCDate, UTCHours,
        UTCMinutes, UTCSeconds, UTCMilliseconds, ISOString, "data-exploration-modal" );
        

        // send email with link
        sendEmailFromTemplate("PROD" as Environment, userEmail,
            "d-ee775244b50c468b90abfa914d0af1a7", "ralph.brooks@whiteowleducation.com", "Real Estate Exploration Data");

        
        setTimeout(() => {
            
            setTimeout(() => {

                setIsSubmitted(true);
            
                        // How do I trigger the exit animation
                        setTimeout(() => {

                            handleClose();
                        }, 3000);
            }, 3000);
        }, 3000);

        
   



        //handleClose();

        console.log("END HANDLE TEST");
    }


    console.log("MODAL TYPE", modalType);
    return (
        <div id="main-div" className={composedStyle}>
            <GlobalStyles />
            <div className={className}>
                <Backdrop onClick={handleClose}>
                    {showModal ? (
                        <motion.div
                            id="orange-bg"
                            onClick={(e) => e.stopPropagation()} // prevent click from closing modal
                            className="modal orange-gradient"
                            variants={dropIn}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                        >
                            {isSubmitted && <div tw="absolute top-1/3 text-elegant-black">Your email should be arriving shortly..</div>}
                            {!isSubmitted && submitting && <div tw="absolute top-1/3 text-elegant-black">Submitting ...</div>}
                            {!isSubmitted && !submitting && (
                                <>
                                    <button className="close-button topright" onClick={handleClose}>&times;</button>

                                    <ModalForm
                                        handleSubmit={handleSubmit}
                                        handleChange={handleChange}
                                    />
                                    <ModalButton
                                        id="no-thanks"
                                        onClick={handleClose}
                                        label="No thanks"
                                        tw="font-NewsGothic"
                                    />
                                </>
                            )}
                        </motion.div>
                    ): null}
                </Backdrop>
            </div>
        </div>
    )

}

const ModalForm = ({handleSubmit, handleChange}) => (
    <div className="modal-text" tw=" mt-14">
        <form onSubmit={handleSubmit} tw="flex flex-col">
                <h3 tw="font-NewsGothic text-center mb-4 text-xl"> Sign up and we will send you the real estate dataset</h3>
                <input 
                id="emailaddress"
                name="emailaddress"
                type="email"
                className='input' 
                placeholder="Enter your email"
                onChange={handleChange}
                />
                <input tw="mt-5 font-NewsGothic border-elegant-black border-2 text-elegant-black bg-transparent py-2 w-3/4 mx-auto" type="submit" value="Submit" />
                
        </form>

    </div>
)

const ModalButton = ({id, onClick, label}) => (
    <motion.button
        id={id}
        onClick={onClick}
    >
        {label}
    </motion.button>
)

export default Modal;