import React, { useEffect, useRef, useState } from 'react'
import helper from '../../../../../../../../utils/helper';


const PendingPriceHistPython: React.FC<{className: string}> = ({className}) => {

    // This contains CSS from PythonStyle above. If this component is moved, that CSS must also move. 

    useEffect(() =>{
        // https://github.com/gerrgg/gregbastianelli showed how to highlight after html code injection
        helper.highlightCode();

        // root color base has to be removed from root so that black does not show on right margin in iphone% and small display phones
        // another change

    });

    // TODO - while has a problem


    const chartCode = (
<pre tw="mt-3 "><code tw="h-auto whitespace-pre inline-block pt-8 text-xs md:text-base " className="hljs language-python whiteowlpython">

price = 0{"\n"}
price_list = [0]{"\n"}
increment = 200000{"\n"}
while price {"<"} 1500000:{"\n"}
    {"\t"}price = price + increment{"\n"}
    {"\t"}price_list.append(price){"\n"}
{"\n"}
def histogram(column_name, price_bins):{"\n"}
    {"\t"}fig, ax = plt.subplots(){"\n"}
    {"\t"}counts, bins, patches = ax.hist(histdf[column_name], bins=price_bins){"\n"}
    {"\t"}{"\n"}
    {"\t"}# Set the ticks to be at the edges of the bins.{"\n"}
    {"\t"}ax.set_xticks(bins){"\n"}
    {"\t"}ax.set_xticklabels(ax.get_xticks(), rotation = 45){"\n"}
    {"\t"}{"\n"}
    {"\t"}plt.subplots_adjust(bottom=0.15){"\n"}
    {"\t"}plt.show(){"\n"}
{"\n"}
histogram('pending_price', price_list){"\n"}
</code></pre>)

    return (
        <>
        {chartCode}
        </>);
}

export default PendingPriceHistPython;