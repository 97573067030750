
import React, { useEffect, useState, useRef, useMemo } from 'react'
// Given data for a singleboxplot, this component
// makes appropriate calculations and plots the 
// single box plot

import { BoxPlotMetrics }from "."


  interface SingleBoxPlotProps {
    translateX1: number // how far over the main line is 
    translateY1: number // min value for the box line 
    translateY2: number // max value for this box line
    translateYMedian: number // y position in pixels where the median is
    initLineColor: string
    selectedLineColor: string
    initBoxColor: string
    selectedBoxColor: string
    initMedianColor: string
    selectedMedianColor: string
    handleSelectedIds: React.Dispatch<React.SetStateAction<string[]>>
    boxY: number
    boxHeight: number
    zidStrings: string[]
}


  // v is the reducer, d is the key

  // d in this context is only used to pass through the data
  export const SingleBoxPlot: React.FC<SingleBoxPlotProps> = ({children, translateX1, translateY1, translateY2, 
    initLineColor, selectedLineColor, initBoxColor, selectedBoxColor, initMedianColor,
     selectedMedianColor, boxY, boxHeight, zidStrings, translateYMedian, handleSelectedIds}) => {

    const [selected, setSelected] = useState(false);

    let lineColor = initLineColor;
    let boxColor = initBoxColor;
    let medianColor = initMedianColor;

    if(selected){
        lineColor = selectedLineColor;
        boxColor = selectedBoxColor;
        medianColor = selectedMedianColor;
    } 

    function drill(zidStrings){

        // If current state is selected, remove data
        if(selected) {
          // Bar is selected and it is about to be UNSELECTED
          // console.log("BOX IS ABOUT to be UNSELECTED");
          handleSelectedIds((prevState) => {
                  const remainingZids: string[] = prevState.filter(n => !zidStrings.includes(n))
                  return remainingZids
              }
          );

      } else
      {
          // console.log("BOX is about to be selected");
          // add data in a non dup way
          handleSelectedIds((prevState) => {
              // let concatArray = prevState.concat(zidStrings);
              let noDuplicateArray = [...new Set([...prevState,...zidStrings])]
              return noDuplicateArray});

      
      }

      setSelected((prevState) => !prevState)
    }





    // There is only one x position for the line. X2=x2
    const translateX2 = translateX1;

    const boxWidth=20;

    const boxLeftSide = translateX1-(boxWidth/2);
    const boxRightSide = translateX1+(boxWidth/2);

    // TODO - add the handle click event



    return (<>
        <g onClick={(e) => drill(zidStrings)}>
          <line x1={translateX1} x2={translateX2}
              y1={translateY1} y2={translateY2}
              stroke={lineColor}
              width={40}
          />
          {/* box and the median are also required */}
          <rect x={boxLeftSide} y ={boxY} height={boxHeight} width={boxWidth}
            stroke="black" fill={boxColor} />

          {/* Median line has the same starting x as the box */}
          <line className="medianline" x1={boxLeftSide} x2={boxRightSide}
            y1={translateYMedian} y2={translateYMedian}
            stroke={medianColor}
            width={80}
          /> 
        </g>
    </>)

  }