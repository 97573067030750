import React, { useEffect, useState, useRef, useMemo } from 'react'
import { Bar } from './Bar'

interface VBarProps {
    bins: any;
    xScale: (value:any) => number;
    yScale: (value:any) => number;
    handleSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
    width: number;
    height: number;
    initFillColor: string;
}


export const VertBars: React.FC<VBarProps> = ({children, bins, width, height, xScale, yScale, initFillColor, handleSelectedIds }) => {

    // TODO reset color
    // TODO height 196
    // 

    useEffect(() => {
        handleSelectedIds([]);
      },[])


    const dms = { width: `${width}px`, height: `${height}px`}

    // console.log("VERT BARS DMS", dms);

    function drill(data){

        //handleSelectedIds(["2072987908"]);

        let zids: string[] = [];

        for (var index in data){
            // Only add the id if it exists
            if( data[index].zid !== undefined) {
                zids.push( data[index].zid as string);
            };
            
        }
        //console.log("SELECTED ZIDS", zids);
        handleSelectedIds(zids);
    }

    return(
        <>
        <svg width={dms.width} height={dms.height}>
            {bins.map((d) => {

            // console.log("MAP STUFF : ", d, d.x0, d.x1);
            let attrHeight = height - yScale(d.length);
            // console.log("attrHeight", attrHeight)

            // TODO - not sure why the -1 is here
            let attrWidth = xScale(d.x1) - xScale(d.x0) -1;
            // console.log("attrWidth", attrWidth)

            // move rectangle to start of x position in pixels (xScale(x0))
            // The START of the rectangle is upper left hand side
            // Y position is how tall the rectangle is in y units
            return (<>

                <Bar
                    attrHeight={attrHeight}
                    attrWidth={attrWidth}
                    translateXVal={xScale(d.x0)}
                    translateYVal={yScale(d.length)}
                    initFill={initFillColor}
                    d={d}
                    handleSelectedIds={handleSelectedIds}
                />
                
                </>
            );

            }

            )}

        </svg>
        </>
    )
 }
