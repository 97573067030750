// This has responsibility to feed the right data to the right singleboxplot
// This means it has the responsibility of figuring out what to do with xscale
// and yscale

// you have to calculate all box plots here to understand 
// because you have to know the range of the line before plotting the line,
// aggregate stats have to be done at this level


import React, { useEffect, useState, useRef, useMemo } from 'react'

import { SingleBoxPlot } from './SingleBoxPlot';

import * as d3 from "d3"
import { HousingIDObject } from '../../../model/housingPrice';

import { BoxPlotMetrics }from "."


/**
 * This is the function that gets the metrics for a single column (a single box plot)
 * @param dataItemsSorted This is a sorted list of floats
 * @param dataElementName This is 
 * @returns 
 */
const rollupFunction = (dataItemsSorted): BoxPlotMetrics => {


    const q1 = d3.quantile(dataItemsSorted,.25)
    const median = d3.quantile(dataItemsSorted, .5)
    const q3 = d3.quantile(dataItemsSorted,.75)
  
    let interQuantileRange = 0;
  
    if (q3 && q1){
        // get other metrics if q3 and q1 are defined
        interQuantileRange = q3 - q1;
    }

    let min: number = dataItemsSorted[0];
    let max: number = dataItemsSorted[dataItemsSorted.length - 1]
    
    return({q1: q1, median: median, q3: q3, interQuantileRange: interQuantileRange, min: min, max: max} as BoxPlotMetrics);
  }



interface VBarProps {

    xScale: d3.ScaleBand<string>
    yScale: (value:any) => number;
    handleSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
    width: number;
    height: number;
    initFillColor: string;
    groupByName: string
    dataElementName: string
    bins: any[]
    graphXAdjustment: number
}


// how do you pass the FULL data associated with the key

export const VertBoxPlots: React.FC<VBarProps> = ({children, bins, width, height, xScale,
             yScale, groupByName, dataElementName, initFillColor, graphXAdjustment, handleSelectedIds }) => {




    // TODO - This does not address how to handle with clamp or percentage

    // Clear what is selected for this particular plot
    useEffect(() => {
        handleSelectedIds([]);
      },[])


    function getXPosition(key: string) {

        // console.log("getXPosition xScale", key, xScale, xScale(key));
        const xPositionBeforeMargin = xScale(key)
        let xPosition = 0 + graphXAdjustment;

        if(xPositionBeforeMargin){
            xPosition = xPositionBeforeMargin + graphXAdjustment;
        }
        
        // There are 2 positions undefined
        if(xPosition){
            return xPosition;
        } else{
            return 0;
        }
    }

    // Iterate through each key and get the rollup metrics

    const dms = { width: `${width}px`, height: `${height}px`}

    const cols:any = []
    for (var key in bins){
        let flatDataForOneKey = bins[key];
        let xPosition = getXPosition(key)
        // console.log("KEY XPOSITION", key, xPosition);
        let sortedData = flatDataForOneKey.map((g) => parseFloat(g[dataElementName])).sort(d3.ascending)
        let minValueOfSortedData = sortedData[0];
        let maxValueOfSortedData = sortedData[sortedData.length - 1];

        let metrics = rollupFunction(sortedData)
        // console.log("METRICS", key, metrics, minValueOfSortedData, maxValueOfSortedData, sortedData);
        let zidStrings: string[] = flatDataForOneKey.map((g) => g['zid']);
        
        // TODO fix the color when box plot is selected
        const singleBoxPlotElement = (
            <SingleBoxPlot
                translateX1={xPosition}
                translateY1={yScale(minValueOfSortedData)}
                translateY2={yScale(maxValueOfSortedData)}
                translateYMedian={yScale(metrics.median)}
                initLineColor="green"
                selectedLineColor="darkgreen"
                initBoxColor="#69b3a2"
                selectedBoxColor="darkgreen"
                initMedianColor="#96FFE7"
                selectedMedianColor="darkgreen"
                handleSelectedIds={handleSelectedIds}
                boxY={yScale(metrics.q3)}
                boxHeight={(yScale(metrics.q1) - yScale(metrics.q3))}
                zidStrings={zidStrings}
            />
        );
        cols.push(singleBoxPlotElement);
    }



    return (
        <>
        <svg width={dms.width} height={dms.height}>
            { cols
            }
        </svg>
        </>
    );
}