import { selectAll } from "./dom";
import * as bug from "./debug";
import generateId from "./generateId";
import err from "./err";
import getIndex from "./getIndex";
import createProgressThreshold from "./createProgressThreshold";
import parseOffset from "./parseOffset";
import indexSteps from "./indexSteps";
import getOffsetTop from "./getOffsetTop";
import { setupScroll, direction, onScroll } from "./scroll";

import { DecimalType, parseOffsetJSON, ScrollamaOptions, scrollCB, StepCallback, 
	StepProgressCallback, updateParameter , OberserversOnly} from ".."

class ScrollamaController {
	public cb: scrollCB;

	private scrollamaId: string;
	public steps: ScrollamaOptions[];
	public globalOffset;
	public containerElement;
	public rootElement;

	public progressThreshold;

	private isEnabled: boolean;
	public isProgress: boolean;
	public isDebug: boolean;
	public isTriggerOnce: boolean;

	private exclude : boolean[];



	constructor(){
		this.cb = {};
		this.scrollamaId = generateId();
		this.steps = [];
		this.progressThreshold = 0;
		this.isEnabled = false;
		this.isProgress = false;
		this.isDebug = false;
		this.isTriggerOnce = false;
		this.exclude = [];


	}

	/* HELPERS */
	public reset = () => {
		this.cb = {
			stepEnter: () => { },
			stepExit: () => { },
			stepProgress: () => { },
		};
		this.exclude = [];
	}

	public handleEnable = (shouldEnable) => {
		if (shouldEnable && !this.isEnabled) this.updateObservers();
		if (!shouldEnable && this.isEnabled) this.disconnectObservers();
		this.isEnabled = shouldEnable;
	}

	/* NOTIFY CALLBACKS */
	// RAB - progress is the percent completion of a step

	// TODO - change back to private
	public notifyProgress = (element, progress: DecimalType) => {
		const index = getIndex(element);
		const step = this.steps[index];
		if (progress !== undefined) step.progress = progress;
		const response = { element, index, progress, direction };
		if (step.state === "enter") {
			if(this.cb.stepProgress)
			{
				this.cb.stepProgress(response);
			}
			else {
				err("Step progress function was expected but not provided.")
			}
		}

	}

	// TODO - change back to private
	public notifyOthers = (index, location) => {
		console.log(index, location, direction);
		if (location === "above") {
			let i = direction === "down" ? 0 : index - 1;
			let end = direction === "down" ? i < index : i >= 0;
			let inc = direction === "down" ? 1 : -1;
			for (i; end; inc) {
				const step = this.steps[i];
				console.log(
					Object.keys(step)
						.map((p) => `${p} - ${step[p]}`)
						.join("\n ")
				);
				if (direction === "down") {
					if (step.state !== "enter" && step.direction !== "down") {
						this.notifyStepEnter(step.node, false);
						this.notifyStepExit(step.node, false);
					} else if (step.state === "enter") this.notifyStepExit(step.node, false);
				} else if (direction === "up") {
					if (step.state !== "enter" && step.direction === "down") {
						this.notifyStepEnter(step.node, false);
						this.notifyStepExit(step.node, false);
					} else if (step.state === "enter") this.notifyStepExit(step.node, false);
				}
			}
		} else if (location === "below") {
			for (let i = this.steps.length - 1; i > index; i -= 1) {
				const step = this.steps[i];
				if (step.state === "enter") this.notifyStepExit(step.node);
				if (step.direction === "down") {
					this.notifyStepEnter(step.node, false);
					this.notifyStepExit(step.node, false);
				}
			}
		}
	}

	// TODO change back to private
	public notifyStepEnter = (element, check = true) => {
		const index = getIndex(element);
		const step = this.steps[index];
		const response = { element, index, direction };

		step.direction = direction;
		step.state = "enter";

		// if (isPreserveOrder && check && direction !== "up")
		//   notifyOthers(index, "above");
		// if (isPreserveOrder && check && direction === "up")
		//   notifyOthers(index, "below");

		if (!this.exclude[index]) this.cb.stepEnter(response);
		if (this.isTriggerOnce) this.exclude[index] = true;
	}

	// TODO change back to private
	public notifyStepExit = (element, check = true) => {
		const index = getIndex(element);
		const step = this.steps[index];

		if (!step.state) return false;

		const response = { element, index, direction };

		// RAB added step.progress is not undefined

		if (this.isProgress && step.progress !== undefined) {
			if (direction === "down" && step.progress < 1) this.notifyProgress(element, 1);
			else if (direction === "up" && step.progress > 0)
			this.notifyProgress(element, 0);
		} else if ( step.progress == undefined ){
			err("RAB - Step progress was not defined");
		}

		step.direction = direction;
		step.state = "exit";

		if(this.cb.stepExit){
			// RAB If stepExit is defined send the response
			this.cb.stepExit(response);
		}
		
	}

	/* OBSERVERS - HANDLING */
	// This is a callback function 


	public resizeStep : ResizeObserverCallback = ([entry]: ResizeObserverEntry[]) => {
		// console.log("inside resize step");
		const index = getIndex(entry.target);
		const step = this.steps[index];

		// RAB making entry target a HTMLElement
		const entryTarget : HTMLElement  = entry.target as HTMLElement;
		const h = entryTarget.offsetHeight;

		if (h !== step.height) {
			step.height = h;
			this.disconnectObserver(step);
			this.updateStepObserver(step);
			this.updateResizeObserver(step);
		}
	}

	// Adding the unused observer parameter

	// TODO change back to private
	public intersectStep = ([entry]: IntersectionObserverEntry[]) => {
		onScroll(this.containerElement);

		const { isIntersecting, target } = entry;
		if (isIntersecting) this.notifyStepEnter(target);
		else this.notifyStepExit(target);
	}

	// TODO change back to private
	public intersectProgress = ([entry]: IntersectionObserverEntry[]) => {
		const index = getIndex(entry.target);
		const step = this.steps[index];
		const { isIntersecting, intersectionRatio, target } = entry;
		if (isIntersecting && step.state === "enter")

		// notifyProgress can only update every 10%
		if (intersectionRatio < 0) err("Intersection ratio must be greater than or equal to 0")
		// RAB you really should throw errors 
		if (intersectionRatio > 1) err("Intersection ratio must be less than or equal to 1")

		const roundedIntersectionRatio: DecimalType = Math.round(intersectionRatio * 10) / 10 as DecimalType;
		this.notifyProgress(target, roundedIntersectionRatio);
	}



	/*  OBSERVERS - CREATION */
	// TODO change back to private
	public disconnectObserver = (obsObject: OberserversOnly ) => {

		const { observers } = obsObject;

		Object.keys(observers).map((name) => {
			observers[name].disconnect();
		});
	}

	// TODO change back to private
	public disconnectObservers = () => {
		this.steps.forEach(this.disconnectObserver);
	}

	// TODO change back to private
	public updateResizeObserver = (step) => {

		// https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
		// Current api does not seem to require observer

		const observer = new ResizeObserver(this.resizeStep);
		observer.observe(step.node);
		step.observers.resize = observer;
	}

	// TODO change back to private
	public updateResizeObservers =() => {
		this.steps.forEach(this.updateResizeObserver);
	}

	// TODO change back to private
	private updateStepObserver = (step) => {
		const h = window.innerHeight;
		const off = step.offset || this.globalOffset;
		const factor = off.format === "pixels" ? 1 : h;
		const offset = off.value * factor;
		const marginTop = step.height / 2 - offset;
		const marginBottom = step.height / 2 - (h - offset);
		const rootMargin = `${marginTop}px 0px ${marginBottom}px 0px`;
		const root = this.rootElement;

		const threshold = 0.5;
		const options = { rootMargin, threshold, root };
		const observer = new IntersectionObserver(this.intersectStep, options);

		observer.observe(step.node);
		step.observers.step = observer;

		if (this.isDebug) {
			const anID = this.scrollamaId;
			const updateInfo : updateParameter = {anID, step, marginTop};
			bug.update(updateInfo);
		}
	}

	// TODO change back to private
	public updateStepObservers = () => {
		this.steps.forEach(this.updateStepObserver);
	}

	// TODO change back to private
	public updateProgressObserver = (step) => {
		const h = window.innerHeight;
		const off = step.offset || this.globalOffset;
		const factor = off.format === "pixels" ? 1 : h;
		const offset = off.value * factor;
		const marginTop = -offset + step.height;
		const marginBottom = offset - h;
		const rootMargin = `${marginTop}px 0px ${marginBottom}px 0px`;

		const threshold = createProgressThreshold(step.height, this.progressThreshold);
		const options = { rootMargin, threshold };
		const observer = new IntersectionObserver(this.intersectProgress, options);

		observer.observe(step.node);
		step.observers.progress = observer;
	}

	// TODO change back to private
	public updateProgressObservers = () => {
		this.steps.forEach(this.updateProgressObserver);
	}

	public updateObservers() {
		this.disconnectObservers();
		this.updateResizeObservers();
		this.updateStepObservers();
		if (this.isProgress) this.updateProgressObservers();
	}
}

/* SETUP */
export default class ScrollamaInstance{


	private controller: ScrollamaController;

	constructor() {
		this.controller = new ScrollamaController();

	}

	public setup = ({
		step,
		parent,
		offset = 0.5,
		threshold = 4,
		progress = 0,
		once = false,
		debug = false,
		container = undefined,
		root = null
	}: ScrollamaOptions) : ScrollamaInstance => {

		setupScroll(container);

		this.controller.steps = selectAll(step, parent).map((node, index) => ({
			index,
			direction: undefined,
			height: node.offsetHeight,
			node,
			observers: {},
			offset: parseOffset(node.dataset.offset),
			top: getOffsetTop(node),
			progress: 0,
			state: undefined,
		}));

		if (!this.controller.steps.length) {
			err("no step elements");
			return this;
		}

		// RAB if progress is above 0 , then isProgress is true
		this.controller.isProgress = progress > 0 ? true : false;

		this.controller.isTriggerOnce = once;
		this.controller.isDebug = debug;
		this.controller.progressThreshold = Math.max(1, +threshold);
		this.controller.globalOffset = parseOffset(offset);
		this.controller.containerElement = container;
		this.controller.rootElement = root;

		this.controller.reset();
		indexSteps(this.controller.steps);
		this.controller.handleEnable(true);
		return this;
	};

	public enable = () : ScrollamaInstance => {
		this.controller.handleEnable(true);
		return this;
	};

	public disable = () : ScrollamaInstance => {
		this.controller.handleEnable(false);
		return this;
	};

	public destroy = () : void => {
		this.controller.handleEnable(false);
		this.controller.reset();

	};

	public resize = () : ScrollamaInstance => {
		this.controller.updateObservers();
		return this;
	};


	// I think this determines the "center" of something in focus 
	public offset = (x: number | string | parseOffsetJSON) : ScrollamaInstance => {
		if (x === null || x === undefined) return this.controller.globalOffset.value;
		this.controller.globalOffset = parseOffset(x);
		this.controller.updateObservers();
		return this;
	};

	public onStepEnter = (callback: StepCallback) : ScrollamaInstance => {
		if (typeof callback === "function") this.controller.cb.stepEnter = callback;
		else err("onStepEnter requires a function");
		return this;
	};

	public onStepExit = (callback: StepCallback) : ScrollamaInstance => {
		if (typeof callback === "function") this.controller.cb.stepExit = callback;
		else err("onStepExit requires a function");
		return this;
	};

	
	public onStepProgress = (callback: StepProgressCallback) : ScrollamaInstance => {
		if (typeof callback === "function") this.controller.cb.stepProgress = callback;
		else err("onStepProgress requires a function");
		return this;
	};

}


// export default ScrollamaInstance;
