
import React, { useEffect, useState, useRef } from 'react'
import { css } from '@emotion/css'
import tw, { GlobalStyles } from "twin.macro"


const tailwindStyle = css`
@tailwind base;
@tailwind components;
@tailwind utilities;
`;



const ToggleStyle = css`


.fixedtoggle {
    z-index: 9999;
    position: fixed;
    top: 50px !important;
    right: -15px !important;
    width: 20%;
    height: 10%;
}

.icon {
    width: 60%
}

// sm 
@media (min-width: 640px) {

    .fixedtoggle {
        z-index: 9999;
        position: fixed;
        top: 50px !important;
        right: -15px !important;
        width: 10%;
        height: 10%;
    }
}

// lg
@media (min-width: 1024px) {

.fixedtoggle {
    width: 7.5%;
}
}

// 2xl
@media (min-width: 1536px) {

.fixedtoggle {
    width: 95.2px;
}
}



`;


const composedStyle = css`
    ${tailwindStyle};
    ${ToggleStyle};
`;

interface IToggleProps {
    className: string;
    handleShowGuide: React.Dispatch<React.SetStateAction<boolean>>
    guideIconRef: any,
    globeIconRef: any
  }


export const ToggleGuide: React.FC<IToggleProps> = ({className, children, handleShowGuide,
    guideIconRef, globeIconRef
}) => {

    const [guideButtonHighlight, setGuideButtonHighlight] = useState(true);
    

    function guideIconClicked(){
        // console.log("Guide clickec")
        // changes color of button
        handleShowGuide(true);
        setGuideButtonHighlight(true);
        
    }

    function mapIconClicked(){
        // console.log("map icon clicked")
        handleShowGuide(false);
        // changes color of button
        setGuideButtonHighlight(false);
    }

    return(
        <>
        <div className={className}>
            <div className={composedStyle}>
                <GlobalStyles />
                <div className="fixedtoggle" tw=" flex flex-col items-center">

                    <button className="mapbutton"  ref={guideIconRef} tw="mt-6" onClick={guideIconClicked} aria-label="map button">
                        {guideButtonHighlight ?
                            <img className="icon" src="/images/blog/2022/10/14/openbook.svg" 
                                alt="Button showing Guide mode is active." /> :
                            <img className="icon" src="/images/blog/2022/10/14/openbookdeactivated.svg" 
                                    alt="Button showing guide mode is not active."/> 
                        }
                    </button>
                    <button className="guidebutton" ref={globeIconRef} tw="mt-2" onClick={mapIconClicked} aria-label="guide button">
                        {!guideButtonHighlight ?
                            <img className="icon" src="/images/blog/2022/10/14/globeicon.svg" 
                                alt="Button showing Globe mode is active."/> :
                            <img className="icon" src="/images/blog/2022/10/14/globeicondeactivated.svg" 
                                alt="Button showing Globe mode is not active."/>
                        }
                    </button>

                </div>
            </div>
        </div>
        </>
    )
}

