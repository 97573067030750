
import React, { useEffect, useState, useRef } from 'react'
import { css } from '@emotion/css'
import tw, { GlobalStyles } from "twin.macro"

import { createPopper } from '@popperjs/core';
import { usePopper } from "react-popper";

const tailwindStyle = css`
@tailwind base;
@tailwind components;
@tailwind utilities;
`;



const TourStyle = css`

.poppercontainer {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: white;
  padding: 20px;
  text-align: center;
  width: 180px;

  transform: translate3d(-83px, -668.333px, 0px)

}

#arrow {
    position: absolute;
    width: 20px;
    height: 20px;
    top:0px;
    right:0px;
    &:after {
      content: " ";
      background-color: white;
      box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.1);
      position: absolute;
      transform: rotate(45deg);
      width: 20px;
      height: 20px;
    }
  }

  &[data-popper-placement^='left'] > #arrow {
    bottom: -30px;
    :after {
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    }
  }

// sm 
@media (min-width: 640px) {


}

// lg
@media (min-width: 1024px) {


}

// 2xl
@media (min-width: 1536px) {


}



`;


const composedStyle = css`
    ${tailwindStyle};
    ${TourStyle};
`;

interface ITourProps {
    className: string;
    elementRef: any;
    popupText: string;

  }


export const Tour: React.FC<ITourProps> = ({className, children, elementRef, popupText}) => {

    // https://codesandbox.io/s/blue-worker-w9rtu?file=/src/App.js


    const referenceElement = elementRef.current;
    const popperRef = useRef(null);

    // useState creates a callbackref
    const [arrowRef, setArrowRef] = useState(null);


    const placement = 'left-end';
    // placement needs to be to the left

    const { styles, attributes } = usePopper(referenceElement, popperRef.current,   
    {
        placement,
        modifiers: [
          {
            name: "arrow",
            options: {
              element: arrowRef
            }
          },
          {
            name: "offset",
            options: {
              offset: [0, 20]
            }
          }
        ]
      }
    );

    // https://codesandbox.io/s/blue-worker-w9rtu?file=/src/App.js:2226-2362

    return(
        <>
        <div className={className}>
            <div className={composedStyle}>
                <GlobalStyles />
                <div className="poppercontainer" ref={popperRef} style={styles.popper} {...attributes.popper}>
                    <div ref={setArrowRef} style={styles.arrow} id="arrow"/>
                     <p tw="text-red-500 text-sm" >{popupText}</p>

                </div>
            </div>
        </div>
        </>
    )
}

